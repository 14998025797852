<template>
  <div>
    <v-container>
      <v-sheet
        color="transparent"
      >
        <v-chip
          class="ma-2"
          color="red"
          text-color="white"
          tag="a"
          @click="getAllCards"
        >all</v-chip>

        <v-chip
          class="ma-2"
          color="red"
          text-color="white"
          tag="a"
          @click="getCairoCards"
        >cairo</v-chip>

        <v-row>
          <v-col
            v-for="(card, i) in sample"
            :key="i"
            cols="12"
            sm="6"
            md="4"
            lg="3"
            xl="2"
          >
            <v-hover v-slot="{ hover }">
              <v-card
                class="mx-auto"
                color="black"
                max-width="600"
                height="100%"
              >
                <v-img
                  :aspect-ratio="16/9"
                  :src="card.img"
                >
                  <v-expand-transition>
                    <div
                      v-if="hover"
                      class="d-flex transition-fast-in-fast-out grey darken-4 v-card--reveal"
                      style="height: 100%;"
                    >
                      <v-btn
                        :to="card.link"
                        tag="a"
                        color="red darken-1"
                        class="ml-auto mr-auto"
                      >
                        {{ $vuetify.lang.t('$vuetify.card.more') }}
                      </v-btn>
                    </div>
                  </v-expand-transition>
                </v-img>
                <v-card-text
                  class="pt-6"
                  style="position: relative;"
                >
                  <h3 class="text-h5 text--white font-weight-light mb-2">
                    {{ $vuetify.lang.t(card.title) }}
                  </h3>
                </v-card-text>
              </v-card>
            </v-hover>
          </v-col>
        </v-row>
      </v-sheet>
    </v-container>
  </div>
</template>

<script>
import { blogEgypt, sakkara } from '@/scripts/images';

export default {
  name: 'Information',
  data: () => ({
    sample: [],
    cards: [
      {
        title: '$vuetify.attractions.abidos.title',
        img: sakkara.sakkaraCard,
        link: '/information/abidos',
      },
      {
        title: '$vuetify.attractions.alexandria.title',
        img: sakkara.sakkaraCard,
        link: '/information/alexandria',
      },
      {
        title: '$vuetify.attractions.aswan.title',
        img: sakkara.sakkaraCard,
        link: '/information/aswan',
      },
      {
        title: '$vuetify.attractions.beniH.title',
        img: sakkara.sakkaraCard,
        link: '/information/beni-hasane',
      },
      {
        title: '$vuetify.attractions.cairo.title',
        img: blogEgypt.cairoCard,
        link: '/information/cairo',
        place: 'cairo',
      },
      {
        title: '$vuetify.attractions.catacombs.title',
        img: sakkara.sakkaraCard,
        link: '/information/kom-el-shouqafas-catacombs',
      },
      {
        title: '$vuetify.attractions.caitbeyCity.title',
        img: sakkara.sakkaraCard,
        link: '/information/caitbey-city-and-lighthouse',
      },
      {
        title: '$vuetify.attractions.saladineCitadel.title',
        img: blogEgypt.ciudadelasaladinoCard,
        link: '/information/saladine-citadel',
        place: 'cairo',
      },
      {
        title: '$vuetify.attractions.dakhlaOasis.title',
        img: sakkara.sakkaraCard,
        link: '/information/dakhla-oasis',
      },
      {
        title: '$vuetify.attractions.deirMedina.title',
        img: sakkara.sakkaraCard,
        link: '/information/deir-medina',
      },
      {
        title: '$vuetify.attractions.romanAmphiteater.title',
        img: sakkara.sakkaraCard,
        link: '/information/the-roman-amphiteater',
      },
      {
        title: '$vuetify.attractions.ashmunein.title',
        img: sakkara.sakkaraCard,
        link: '/information/el-ashmuneina',
      },
      {
        title: '$vuetify.attractions.copticCairo.title',
        img: blogEgypt.barriocoptoCard,
        link: '/information/coptic-cairo',
      },
      {
        title: '$vuetify.attractions.fayoum.title',
        img: sakkara.sakkaraCard,
        link: '/information/the-fayoum',
      },
      {
        title: '$vuetify.attractions.minya.title',
        img: sakkara.sakkaraCard,
        link: '/information/minya',
      },
      {
        title: '$vuetify.attractions.nubianMuseum.title',
        img: sakkara.sakkaraCard,
        link: '/information/the-nubian-village',
      },
      {
        title: '$vuetify.attractions.unfinishedObeliskAswan.title',
        img: sakkara.sakkaraCard,
        link: '/information/unfinished-obelisk-of-aswan',
      },
      {
        title: '$vuetify.attractions.nubianVillage.title',
        img: sakkara.sakkaraCard,
        link: '/information/the-nubian-village',
      },
      {
        title: '$vuetify.attractions.filaeTemple.title',
        img: sakkara.sakkaraCard,
        link: '/information/filae-temple',
      },
      {
        title: '$vuetify.attractions.farafraOasis.title',
        img: sakkara.sakkaraCard,
        link: '/information/farafra-oasis',
      },
      {
        title: '$vuetify.attractions.hurgada.title',
        img: sakkara.sakkaraCard,
        link: '/information/hurgada',
      },
      {
        title: '$vuetify.attractions.khanKhalilliMarket.title',
        img: blogEgypt.khanelkhaliliCard,
        link: '/information/khan-el-khalilli-market',
      },
      {
        title: '$vuetify.attractions.khargaOasis.title',
        img: sakkara.sakkaraCard,
        link: '/information/kharga-oasis',
      },
      {
        title: '$vuetify.attractions.alexandriaLibrary.title',
        img: sakkara.sakkaraCard,
        link: '/information/the-new-alexandria-library',
      },
      {
        title: '$vuetify.attractions.pompeyPillar.title',
        img: sakkara.sakkaraCard,
        link: '/information/pompeys-pillar',
      },
      {
        title: '$vuetify.attractions.highDam.title',
        img: sakkara.sakkaraCard,
        link: '/information/the-high-dam',
      },
      {
        title: '$vuetify.attractions.pyramidsGuiza.title',
        img: sakkara.sakkaraCard,
        link: '/information/the-pyramids-of-guiza',
      },
      {
        title: '$vuetify.attractions.luxor.title',
        img: sakkara.sakkaraCard,
        link: '/information/luxor',
      },
      {
        title: '$vuetify.attractions.madinetHabu.title',
        img: sakkara.sakkaraCard,
        link: '/information/madinet-habu',
      },
      {
        title: '$vuetify.attractions.memphis.title',
        img: blogEgypt.memfisCard,
        link: '/information/memphis',
      },
      {
        title: '$vuetify.attractions.cairoArchaeologicalMuseum.title',
        img: blogEgypt.museocairoCard,
        link: '/information/сairo-archaeological-museum',
      },
      {
        title: '$vuetify.attractions.bahariyaOasis.title',
        img: sakkara.sakkaraCard,
        link: '/information/bahariya-oasis',
      },
      {
        title: '$vuetify.attractions.oasisEgypt.title',
        img: sakkara.sakkaraCard,
        link: '/information/oasis-egypt',
      },
      {
        title: '$vuetify.attractions.sakkara.title',
        img: blogEgypt.sakkaraCard,
        link: '/information/sakkara',
      },
      {
        title: '$vuetify.attractions.sharmShiekh.title',
        img: sakkara.sakkaraCard,
        link: '/information/the-sharm-shiekh',
      },
      {
        title: '$vuetify.attractions.siwaOasis.title',
        img: blogEgypt.siwaCard,
        link: '/information/siwa-oasis',
      },
      {
        title: '$vuetify.attractions.talAmarna.title',
        img: sakkara.sakkaraCard,
        link: '/information/tal-el-amarna',
      },
      {
        title: '$vuetify.attractions.denderaTemple.title',
        img: sakkara.sakkaraCard,
        link: '/information/the-dendera-temple',
      },
      {
        title: '$vuetify.attractions.karnakTemple.title',
        img: sakkara.sakkaraCard,
        link: '/information/karnak-temple',
      },
      {
        title: '$vuetify.attractions.komOmboTemple.title',
        img: sakkara.sakkaraCard,
        link: '/information/kom-ombo-temple',
      },
      {
        title: '$vuetify.attractions.luxorTemple.title',
        img: sakkara.sakkaraCard,
        link: '/information/luxor-temple',
      },
    ],
  }),

  methods: {
    getCairoCards() {
      this.sample = this.cards.filter((card) => card.place === 'cairo');
    },
    getAllCards() {
      this.sample = this.cards;
    },
  },

  mounted() {
    this.sample = this.cards;
  },
};
</script>

<style lang="scss">
.v-card--reveal {
  align-items: center;
  bottom: 0;
  justify-content: center;
  opacity: 0.95;
  position: absolute;
  width: 100%;
}
</style>
